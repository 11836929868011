<template>
  <div>
    <a-spin :spinning="loading">
      <div class="document-info-box sfm-flex sfm-justify-center sfm-items-center">
        <div class="document-info">
          <component
            :is="currentComponent"
            :info="data"
          />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { UserUploadRecordModel } from '@/models/user_upload_record.js'
const userUploadRecordModel = new UserUploadRecordModel()

export default {
  name: 'ShowUserUploadRecord',
  components: {
    Document: () => import('@/pages/task/user_upload_records/ShowDocument'),
    Software: () => import('@/pages/task/user_upload_records/ShowSoftware.vue')
  },
  data() {
    return {
      data: {},
      loading: true,
      currentComponent: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      userUploadRecordModel.show(this.id).then(res => {
        if(res.code === 0) {
          this.data = res.result
          if (res.result.record_type_slug === 'document') {
            this.currentComponent = 'Document'
          } else {
            this.currentComponent = 'Software'
          }
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.document-info-box {
  width: 100%;

  .document-info {
    width: 500px;
    min-height: 500px;
  }
}

.ant-form-item {
  margin-bottom: 5px;
}
</style>
